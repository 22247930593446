import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { ThriveFullscreenLoading } from '@thriveglobal/thrive-web-leafkit'
import {
    captureMessage,
    store,
    useCrossAppNavigation
} from '@thriveglobal/thrive-web-core'
import { selectIsLoggedIn } from '../../../store/selectors/auth'

export const REDIRECT_URI = 'REDIRECT_URI'
const LOGIN_PATH = '/login'
export const SESSION_ENDED_PATH = '/login/session-ended'
const LOGOUT_PATH = '/logout'

export function isLoginOrLogoutUrl(path: string) {
    // We can replace these with startsWith when we are sure IE isn't a problem
    return (
        path.indexOf(LOGIN_PATH) === 0 ||
        path.indexOf(LOGOUT_PATH) === 0 ||
        path.indexOf(SESSION_ENDED_PATH) === 0
    )
}

export function getUrlSearch() {
    const currentPathName = window.location.pathname
    const hash = window.location.hash
    const currentSearch = new URLSearchParams(window.location.search)
    const newSearch = new URLSearchParams()
    if (currentSearch.has('relay') || isLoginOrLogoutUrl(currentPathName)) {
        return currentSearch.toString()
    }
    newSearch.set(
        'relay',
        `${currentPathName}?${currentSearch.toString()}${hash}`
    )
    return newSearch.toString()
}

export const LoginRedirect = () => {
    const [redirectRoute, setRedirectRoute] = useState<string | null>(null)
    const authStore = store.getState().auth
    const isLoggedIn = selectIsLoggedIn(authStore)
    const navigate = useCrossAppNavigation()
    const path = window.location.pathname
    useEffect(() => {
        // If previously logged in, trigger session ended flow
        if (isLoggedIn && !isLoginOrLogoutUrl(path)) {
            navigate('/login/session-ended')
            return
        }
        captureMessage(
            `User not logged in, setting redirect route to ${LOGIN_PATH}`
        )
        setRedirectRoute(LOGIN_PATH)
    }, [isLoggedIn, path])
    return (
        <React.Fragment>
            {!redirectRoute && <ThriveFullscreenLoading />}
            {redirectRoute && (
                <Redirect
                    to={{
                        pathname: redirectRoute,
                        search: getUrlSearch()
                    }}
                />
            )}
        </React.Fragment>
    )
}
